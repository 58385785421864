
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import MotivoDevolucion from '@/store/entities/OZONE/motivoDevolucion';
import PageRequest from '@/store/entities/page-request'
class PageClienteRequest extends PageRequest {
  nombre: string
}
@Component
export default class SelectMotivoDevolucion extends AbpBase {
  @Prop({ type: Object, default: null }) value: MotivoDevolucion
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Boolean, default: false }) required: Boolean
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  cliente: MotivoDevolucion = null
  pagerequestClientes: PageClienteRequest = new PageClienteRequest()
  maxResultCountCliente = 10
  skipCountCliente = 0
  get clienteList() {
    return this.$store.state.motivoDevolucion.list
  }
  get clienteCount() {
    return this.$store.state.motivoDevolucion.totalCount
  }

  async created() {
    this.getClientesDropDown()
  }

  async getClientes(params: any) {
    this.maxResultCountCliente = 50
    this.skipCountCliente = 0
    this.pagerequestClientes.maxResultCount = this.maxResultCountCliente
    this.pagerequestClientes.skipCount = this.skipCountCliente
    await this.$store.dispatch({
      type: 'motivoDevolucion/getAll',
      data: this.pagerequestClientes
    })
  }

  async nextPage(offset: number) {
    this.pagerequestClientes.skipCount = offset
    await this.$store.dispatch({
      type: 'motivoDevolucion/getAll',
      data: this.pagerequestClientes
    })
  }

  async getClientesDropDown() {
    this.pagerequestClientes.maxResultCount = this.maxResultCountCliente
    this.pagerequestClientes.skipCount = this.skipCountCliente
    await this.$store.dispatch({
      type: 'motivoDevolucion/getAll',
      data: this.pagerequestClientes
    })
  }

  clienteRule = {
    cliente: {
      // required: { required: this.required },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Motivo')),
      placeholder: this.L('Motivo'),
      trigger: 'blur'
    }
  }
}
