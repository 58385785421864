
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import SelectCampana from "../campana/selectCampana.vue";
import SelectMotivoDevolucion from "../motivoDevolucion/selectMotivoDevolucion.vue";
import Campana from "../../../store/entities/OZONE/campana";
import PageRequest from "../../../store/entities/page-request";
import VueBarcode from "vue-barcode";
import MotivoDevolucion from "../../../store/entities/OZONE/motivoDevolucion";
import moment from "moment";
import { Moment } from "moment";
import Devolucion from "../../../store/entities/OZONE/devolucion";
class PageEnvioRequest extends PageRequest {
  searchName: string;
  searchAddress: string;
  searchCP: string;
  referencia: string;
  mailing: string;
  accion: string;
  fechaInicio: Date;
  fechaFin: Date;
  campana: Campana;
  enviado: Boolean;
  pendiente: Boolean;
  devuelto: Boolean;
  pageSize: number;
}
@Component({
  components: {
    SelectCampana,
    SelectMotivoDevolucion,
    VueBarcode,
  },
})
export default class Devoluciones extends AbpBase {
  reference = "";
  prevReference = "";
  searchName = "";
  searchAddress = "";
  searchCP = "";
  mailing = "";
  refT = "refAll";
  activeMotive: number = -1;
  prevousMotive:  number = -1;
  campana: Campana = null;
  motivo: MotivoDevolucion = null;
  marcaje: string = "manual";
  fechaDevolucion: Moment = moment();
  showSignle: boolean = false;
  count = 0;
  show = false;
  sort: string = "id desc";
  innerpagerequest: PageEnvioRequest = new PageEnvioRequest();
  innerpagination: any = {};

  refSearchType = [
    {
      value: "refAll",
      label: "Total",
      id: 0,
    },
    {
      value: "refInit",
      label: "Inicio",
      id: 1,
    },
    {
      value: "refEnd",
      label: "Final",
      id: 2,
    },
  ];

  get innerData() {
    return this.$store.state.envio.devoluciones.pageResult.items;
  }

  getReferencia(){
    return this.$store.state.envio.devoluciones.pageResult.reference;
    
  }
  get pageSize() {
    return this.$store.state.envio.pageSize;
  }

  get totalCount() {
    return this.$store.state.envio.totalCount;
  }

  get currentPage() {
    return this.$store.state.envio.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("envio/setCurrentPage", page);
    this.getInnerPage();
  }

  get campanaList() {
    return this.$store.state.campana.selects;
  }

  get loading() {
    return this.$store.state.envio.loading;
  }

  get motivosList() {
    return this.$store.state.motivoDevolucion.list;
  }

  async created() {
    this.$store.commit("envio/clearData");
    await this.getMotivos();
  }

  mounted() {
    document.addEventListener("keydown", this.doSave);
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.doSave);
  }
  async mensajeCambioMotivo(motivo, id) {
    if (this.prevousMotive == -1) {
      this.activeMotive = id;
      this.motivo = this.motivosList.find((m) => m.id == id);
      this.prevousMotive = this.activeMotive;
    } else {
      this.$confirm({
        title: '¿Desea cambiar el motivo?',
        content: "¿Desea continuar? Al marcar Sí se cambiará el motivo. ",
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        icon: 'warning',
        onCancel: () => {
          motivo = null;
          this.activeMotive = this.motivo.id;
          this.$message.error("Se ha mantenido el motivo, ningún cambio ha sido realizado.", 5);
        },
        onOk: () => {
          this.motivo = this.motivosList.find((m) => m.id == id);
          this.activeMotive = this.motivo.id;
          this.$message.success("Se cambió el motivo correctamente.", 5);
          this.prevousMotive = this.activeMotive;
        }
      });
    }
  }

  changeMotivo(id) {
    if (id === -1 ) {
      this.motivo = null;
      this.prevousMotive = -1;
      
    } 
    else {
      this.mensajeCambioMotivo(this.motivo, id);
    }
  }

  doSave(e) {
    if (e.keyCode === 107) {
      this.marcarAuto();
      e.preventDefault();
    }
    

    if (e.keyCode === 123) {
      this.prevousMotive=0;      
      if (this.motivo != null) {        
        var index123 = this.motivosList.indexOf(this.motivo);
        if (index123 >= 0 && index123 < this.motivosList.length - 1) {
          this.$confirm({
            title: '¿Desea cambiar el motivo?',
            content: "¿Desea continuar? Al marcar Sí se cambiará el motivo. ",
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            icon: 'warning',
            onOk: () => {            
            var nextItem123 = this.motivosList[index123 + 1];
            this.motivo = nextItem123;
            this.activeMotive = this.motivo.id;
            this.$message.success("Se cambió el motivo correctamente.", 5);
            }, 
            onCancel: () => {
              var nextItem123 = this.motivosList[index123];
              this.motivo = nextItem123;
              this.activeMotive = this.motivo.id;
              this.$message.error("Se ha mantenido el motivo, ningún cambio ha sido realizado.", 5);              
            }
          });
        } else {
          this.motivo = null;
          this.activeMotive = -1;
        }
      } else {
        var nextItem123 = this.motivosList[0];
        this.motivo = nextItem123;
        this.activeMotive = this.motivo.id;
      }

      e.preventDefault();
    }

    if (e.keyCode === 122) {
      this.prevousMotive=0;
      if (this.motivo != null) {
        var index122 = this.motivosList.indexOf(this.motivo);
        if (index122 >= 1 && index122 < this.motivosList.length) {
          this.$confirm({
            title: '¿Desea cambiar el motivo?',
            content: "¿Desea continuar? Al marcar Sí se cambiará el motivo. ",
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            icon: 'warning',
            onOk: () => {
              var nextItem122 = this.motivosList[index122 - 1];
              this.motivo = nextItem122;
              this.activeMotive = this.motivo.id;
              this.$message.success("Se cambió el motivo correctamente.", 5);              
            }, 
            onCancel: () => {
              var nextItem122 = this.motivosList[index122];
              this.motivo = nextItem122;
              this.activeMotive =this.motivo.id;
              this.$message.error("Se ha mantenido el motivo, ningún cambio ha sido realizado.", 5);
            }
          });
        } else {
          this.motivo = null;
          this.activeMotive = -1;
        }
      } else {
        var nextItem122 = this.motivosList[this.motivosList.length - 1];
        this.motivo = nextItem122;
        this.activeMotive = this.motivo.id;
      }
      e.preventDefault();
    }
  }

  



  searchAuto() {
    this.$nextTick().then(async () => {
      if (
        this.prevReference &&
        this.prevReference === this.reference &&
        this.marcaje == "auto" &&
        this.showSignle
      ) {
        await this.marcarAuto().then(() => { });
      } else {
        this.prevReference = this.reference;

        this.currentPage = 1;
        //await this.getInnerPage();
        if (this.marcaje == "auto") {
          this.reference = "";
        }
      }
    });
  }

  search() {
    this.$nextTick().then(async () => {
      this.currentPage = 1;
    });
  }

  markChange(value) {
    if (this.marcaje == "auto") {
      this.searchName = "";
      this.searchAddress = "";
      this.searchCP = "";
      this.refT = "refAll";
      (this.$refs.mainref as any).focus();
      this.$message.info("Modo marcaje automático activado", 5);
    } 
    else if (this.marcaje == "manual") {
      this.$message.info("Se ha cambiado a modo marcaje manual", 5);
    } 
    else if (this.marcaje == "edit") {
      this.$message.info("Se ha cambiado a modo rectificación", 5);
    } 
    else {
      this.$message.info("Se ha cambiado a modo anulación", 5);
    }
  }

  async marcarAuto() {
    if (this.showSignle) {
      let id = this.innerData[0].id;
      let referencia = this.innerData[0].referencia;
      await this.marcar(id,referencia);
    }
  }

  async marcar(id,referencia) {    
    console.log("referencia: "+ referencia);
    
    var devolucion = {      
      id: id,
      referencia: referencia,
      motivoDevolucionId: this.motivo.id,
      fechaDevolucion: this.fechaDevolucion.format(),

    };

    var action = "devolucion";
    if (this.marcaje == "del") {
      action = "anularDevolucion"
    }

    await this.$store
      .dispatch({
        type: "envio/" + action,
        data: devolucion,
      })
      .then(() => {
        this.prevReference = "";
        this.reference = "";
        this.searchName = "";
        this.searchAddress = "";
        this.searchCP = "";
        this.showSignle = false;
        this.$store.commit("envio/clearData");
        if(this.innerpagerequest.referencia==null){
        }
        
        this.$message.success("Envio " +  referencia + " marcado como devuelto", 5);
        this.count = this.count + 1;
        this.show = true;

        if (this.marcaje == "del") {
          this.marcaje = "manual";
          this.markChange(null);
        }
      });
  }

  handleInnerTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("envio/setCurrentPage", pagination.current);
    this.$store.commit("envio/setPageSize", pagination.pageSize);
    await this.getInnerPage();
  };

  stateColorScheme(state: number) {
    switch (state) {
      case 1:
        return "ant-tag-primary";
      case 3:
        return "ant-tag-secondary";
      case 4:
        return "ant-tag-success";
      case 5:
        return "ant-tag-danger";
      case 6:
        return "ant-tag-warning";
    }
  }

  async getMotivos() {
    await this.$store.dispatch({
      type: "motivoDevolucion/getAll",
      data: {
        maxResultCount: 100,
        skipCount: 0,
      },
    });
  }

  

  callback(val) {
    console.log(val);
  }

  async getInnerPage() {
    this.innerpagerequest.maxResultCount = this.innerpagination.pageSize;
    this.innerpagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    this.innerpagerequest.sorting = this.sort;
    this.innerpagerequest.pageSize = this.pageSize;
    this.innerpagerequest.campana = this.campana;
    this.innerpagerequest.searchName = this.searchName;
    this.innerpagerequest.searchAddress = this.searchAddress;
    this.innerpagerequest.searchCP = this.searchCP;
    this.innerpagerequest.mailing = this.mailing;
    this.innerpagerequest.accion = this.marcaje;
    this.innerpagerequest.referencia =
      (this.reference && this.refT == "refEnd" ? "$" : "") +
      this.reference +
      (this.reference && this.refT == "refInit" ? "$" : "");
    this.innerpagerequest.enviado = true;
    await this.$store.dispatch({
      type: "envio/getDevoluciones",
      data: this.innerpagerequest,
    });

    this.showSignle = this.$store.state.envio.devoluciones.singleResult;

    if (this.$store.state.envio.devoluciones.notFound) {
      this.$notification.error({
        message: "Envío No Encontrado",
        description:
          "No hemos encontrado ningún envío con estas características.",
        placement: "bottomRight",
      });
    }

    
    if (this.$store.state.envio.devoluciones.returnedAt) {
      this.$notification.warning({
        message: "Envío Ya Devuelto",
        description:
          "El envío ya fue marcado como devuelto el: " +
          this.$store.state.envio.devoluciones.returnedAt,
        placement: "bottomRight",
      });
      this.$store.state.envio.devoluciones.returnedAt= null;
    }
    
    const pagination = { ...this.innerpagination };
    pagination.total = this.$store.state.envio.totalCount;
    pagination.showSizeChanger = true;
    pagination.pageSizeOptions = ["10", "50", "100"];
    pagination.showTotal = () =>
      `${this.$store.state.envio.totalCount} envios.`;
    pagination.pageSize = this.$store.state.envio.pageSize;
    pagination.current = this.$store.state.envio.currentPage;
    this.innerpagination = pagination;
  }

  innerColumns = [
    {
      title: this.L("Nombre"),
      scopedSlots: { customRender: "func" },
    },
    {
      title: this.L("Direccion"),
      scopedSlots: { customRender: "dir" },
    },
    {
      title: this.L("Campaña"),
      scopedSlots: { customRender: "camp" },
    },
    {
      title: this.L("Estado"),
      scopedSlots: { customRender: "status" },
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];
}
