export default class  PagedFilterAndSortedRequest{
    maxResultCount:number;
    skipCount:number;
    sorting:string;
    where:string;
   
}

const maxResults = new PagedFilterAndSortedRequest()
maxResults.maxResultCount = 2147483647
maxResults.skipCount = 0
export { maxResults }