
import AbpBase from "../../../lib/abpbase";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Campana from "../../../store/entities/OZONE/campana";
import PageRequest from "../../../store/entities/page-request";
class PageCampanaRequest extends PageRequest {
  nombre: string;
  tipoCampana: number;
}
@Component
export default class SelectCampana extends AbpBase {
  @Prop({ type: Object, default: null }) value: Campana;
  @Prop({ type: Object, default: null }) filter: any;
  @Prop({ type: String, default: "" }) keyCode: string;
  @Prop({ type: Boolean, default: true }) showPagination: Boolean;
  @Prop({ type: Boolean, default: true }) tipo: number;
  @Prop({ type: Object, default: null }) rules: Object;
  @Prop({ type: Boolean, default: false }) disabled: Boolean;
  @Prop({ type: Object, default: () => ({}) }) labelCol: Object;
  @Prop({ type: Object, default: () => ({}) }) wrapperCol: Object;

  campana: Campana = null;
  pagerequestCampanas: PageCampanaRequest = new PageCampanaRequest();
  maxResultCountCampana = 10;
  skipCountCampana = 0;
  get campanaList() {
    var list = this.$store.state.campana.selects;
    if (!(list.find((e) => e.id == 0) != null)) {
      list.unshift({
        id: 0,
        nombre: "Todas",
      });
    }
    return list;
  }
  get campanaCount() {
    return this.$store.state.campana.totalCount;
  }

  async created(params: any) {
    this.pagerequestCampanas.nombre = params;
    this.pagerequestCampanas.tipoCampana = this.tipo;
    this.maxResultCountCampana = 10;
    this.skipCountCampana = 0;
    this.pagerequestCampanas.maxResultCount = this.maxResultCountCampana;
    this.pagerequestCampanas.skipCount = this.skipCountCampana;
    if (this.filter && this.filter.tipoCampanaId) {
      this.pagerequestCampanas.tipoCampana = this.filter.tipoCampanaId;
    } else {
      this.pagerequestCampanas.tipoCampana = null;
    }
    await this.$store.dispatch({
      type: "campana/getSelect",
      data: this.pagerequestCampanas,
    });
  }

  async activated() {
    await this.getCampanasDropDown().then(() => {
      return;
    });
  }

  async nextPage(offset: number) {
    this.pagerequestCampanas.skipCount = offset;
    await this.$store.dispatch({
      type: "campana/getAll",
      data: this.pagerequestCampanas,
    });
  }

  async getCampanasDropDown() {
    this.pagerequestCampanas.maxResultCount = this.maxResultCountCampana;
    this.pagerequestCampanas.skipCount = this.skipCountCampana;
    await this.$store.dispatch({
      type: "campana/getAll",
      data: this.pagerequestCampanas,
    });
  }

  campanaRule = {
    campana: {
      // required: { required: true },
      message: this.L("ThisFieldIsRequired", undefined, this.L("Campana")),
      placeholder: this.L("Campana"),
      trigger: "blur",
    },
  };
}
